@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@200&display=swap);

html, body {
  height: 100% !important;
  margin: 0;
  background-color: rgb(232 233 234);
  font-family: 'Roboto', sans-serif;
}

#root {
  height: 100%;
}

